<template>
  <div class="fixed inset-0 flex justify-center z-50 security-container">
    <div class="security-container__content">
      <div class="header flex justify-between">
        <SecurityIcon />
        <div class="flex flex-col flex-1 ml-3">
          <h1 class="header__title">Система безопасности</h1>
          <p v-if="successInvite" class="header__text">
            Вы успешно приняли приглашение, теперь вам нужно установить пароль
            для входа в SKIF.PRO
          </p>
          <p v-else class="header__text">
            Возможно, ваш текущий пароль <br />
            скомпрометирован, поэтому <br />
            администратор изменил его.
          </p>
        </div>
      </div>
      <div class="content mt-4">
        <!-- #TODO make verification for password level / fix why showPasssord is not showing  -->
        <div>
          <CustomInput
            v-model="passwordForm.pass"
            :label="$t('loginPage.password')"
            :type="isTypePass ? 'password' : 'text'"
            :placeholder="'Введите пароль '"
            :isHint="true"
            :content="$t('loginPass.recovery.hint')"
            @isPasswordVisible="isTypePass = !isTypePass"
            @input="handlePassword"
            :error="passwordErrorMessage.length ? true : false"
          />
          <span class="errorMessage" v-if="passwordErrorMessage.length">{{
            passwordErrorMessage
          }}</span>
        </div>
        <div>
          <CustomInput
            class="mt-3"
            v-model="passwordForm.confirm"
            :label="'Пароль ещё раз:'"
            :type="isTypePassConfirm ? 'password' : 'text'"
            :placeholder="'Введите пароль '"
            :isHint="true"
            :content="$t('loginPass.recovery.hint')"
            @isPasswordVisible="isTypePassConfirm = !isTypePassConfirm"
            @input="handlePasswordConfirm"
            :error="passwordConfrimErrorMessage.length ? true : false"
          />
          <span v-if="passwordConfrimErrorMessage.length" class="errorMessage"
            >{{ passwordConfrimErrorMessage }}
          </span>
        </div>
      </div>

      <div class="footer">
        <button @click="save">{{ $t('save') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import SecurityIcon from './assets/SecurityIcon.vue'
import CustomInput from './components/Custom-input.vue'
import { mapState } from 'vuex'
import { loginApi } from '@/api'

export default {
  name: 'SecuritySystem',
  components: {
    SecurityIcon,
    CustomInput
  },
  data() {
    return {
      isComposedReport: false,
      passwordForm: {
        pass: '',
        confirm: ''
      },
      isTypePass: true,
      isTypePassConfirm: true,
      isStrongPassword: false,
      role: '',
      passwordErrorMessage: '',
      passwordConfrimErrorMessage: '',
      highRoles: ['SUPERVISOR', 'ADMIN', 'TARIFF_EDITOR', 'CONTROLLER']
    }
  },
  computed: {
    ...mapState('login', {
      me: (state) => state.me,
      successInvite: (state) => state.successInvite
    })
  },
  methods: {
    save() {
      const formData = {}
      formData.newPassword = this.passwordForm.pass
      if (
        !this.passwordErrorMessage.length &&
        !this.passwordConfrimErrorMessage.length
      ) {
        loginApi.changeMyPassword(
          formData,
          () => {
            // this.$router.push('/')
            this.$store.commit('login/SET_CHANGE_PASSWORD', false)
            this.$emit('close')
          },
          (error) => {
            this.passwordErrorMessage = error.response.data.message
          }
        )
      }
    },
    handlePassword(val) {
      let isHighRole

      if (this.highRoles.includes(this.role)) {
        isHighRole = true
      }

      const strongPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:',"<.>\/?]).{15,25}$/

      const adminPasswordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[~!@#$%^&*()\-+=\[\]{}|;:',"<.>\/?]).{8,25}$/

      const standardPasswordRegex = /^(?=.*[a-zA-Z])(?=.*\d).{5,25}$/

      if (this.isStrongPassword && isHighRole) {
        if (!strongPasswordRegex.test(val)) {
          console.log(isHighRole)
          this.passwordErrorMessage = `Пароль должен содержать от 15 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        } else {
          this.passwordErrorMessage = ''
        }
      } else if (this.isStrongPassword) {
        if (!adminPasswordRegex.test(val)) {
          this.passwordErrorMessage = `Пароль должен содержать от 8 до 25 символов и состоять как минимум из одной заглавной английской буквы, как минимум одной строчной английской буквы и как минимум одного специального символа (~ ! @ # $ % ^ & * ( ) - = + [ '{ ] '} | ; : ' " , < . > / ?)`
        } else {
          this.passwordErrorMessage = ''
        }
      } else {
        this.passwordErrorMessage = standardPasswordRegex.test(val)
          ? ''
          : `Пароль должен состоять от 5 до 25 символов и состоять как минимум из одной английской буквы и как минимум одной цифры`
      }
    },
    handlePasswordConfirm(val) {
      if (val !== this.passwordForm.pass) {
        this.passwordConfrimErrorMessage = 'Пароли должны совпадать'
      } else {
        this.passwordConfrimErrorMessage = ''
      }
    }
  },
  mounted() {
    this.isStrongPassword = this.me.active_company.strong_password
    this.role = this.me.role.key
  }
}
</script>

<style lang="scss">
.security-container {
  font-family: Source Sans Pro;
  background-color: rgba(0, 0, 0, 0.39);
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  vertical-align: top;
  display: inline-block;
  text-align: center;
  &__content {
    background-color: #fff;
    width: 360px;
    margin-top: 40px;
    border-radius: 12px;
    padding: 20px;
    text-align: left;
    margin: 40px auto;
    .errorMessage {
      font-size: 12px;
      color: #c93131;
    }
  }
  .header {
    &__title {
      color: red;
      font-size: 20px;
      color: #292929;
    }
    &__text {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 20px;
    border-top: 1px solid #cdd4dd;
    button {
      width: 100%;
      background: #3a7cca;
      padding: 10px;
      border-radius: 12px;
      margin-top: 10px;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 19px;
    }
  }
}
</style>
